<template>
  <div class="activeDetail">
    <van-icon
      name="arrow-left"
      @click="goBack"
      style="margin-top:20px;margin-left:10px;"
      size="20"
      v-if="backShow"
    />
    <div class="activeLIstBox" v-if="dataContent">
      <div class="activeInformation">
        <div class="info_title">
          <span class="info_intro">{{dataContent.name}}</span>
          <span v-if="dataContent.status === 5" class="info_state">已结束</span>
          <span v-else class="info_state">进行中</span>
        </div>
        <p class="font_style">
          <span v-if="timeShow">开始时间</span>
          <span v-if="!timeShow">活动时间</span>
          {{dataContent.activityBeginTime}}
        </p>
        <p class="font_style" v-if="timeShow">
          <span>结束时间</span>
          {{dataContent.activityEndTime}}
        </p>
        <p class="font_style">
          <span>联系人</span>
          {{dataContent.leaderName}}
        </p>
        <div class="font_icon">
          <span>联系电话</span>
          <span>{{dataContent.leaderPhone}}</span>
          <a :href="'tel:' + dataContent.leaderPhone">
            <div>
              <img src="../../../assets/images/dianhua.png" alt />去联系
            </div>
          </a>
        </div>
        <div class="font_icon">
          <span>活动地点</span>
          <span>{{dataContent.activityLocation}}</span>
        </div>
        <div class="join_person">参加人({{memberList.length}}人)</div>
        <div class="person_head">
          <div v-for="(item,index) in memberList.slice(0,6)" :key="index">
            <div v-if="item.name.length < 3" class="head_wrapper">{{item.name}}</div>
            <div
              v-else
              class="head_wrapper"
            >{{item.name.substring(item.name.length-3,item.name.length)}}</div>
            <!-- <div v-if="item.signIn" class="success_div">已打卡</div> -->
          </div>
          <div v-if="headShow" class="person_head">
            <div v-for="(item,index) in memberList.slice(6)" :key="index">
              <div class="head_wrapper">{{item.name}}</div>
              <!-- <div v-if="item.signIn" class="success_div">已打卡</div> -->
            </div>
          </div>
          <div
            v-if="memberList.length > 6"
            :class="{ 'jiantou_wrapper': true, 'rotate_jiantou': headShow}"
            @click="headShow = !headShow"
          >
            <img src="../../../assets/images/jiantou.png" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- 简介 -->
    <div class="activeLIstBox briefIntroduction briefIntroductionTwo" v-if="dataContent">
      <div class="activeItroduction">活动简介</div>
      <p class="active_info">{{dataContent.summary}}</p>
    </div>
    <!-- 打卡 -->
    <div class="daka_wrapper">
      <div>
        <div class="daka_active" @click="onSignIn">
          <span>活动签到</span>
        </div>
      </div>
    </div>
    <van-overlay :show="window_show">
      <div class="wrapper" @click.stop>
        <div class="success_block">
          <div class="success_head">
            <img src="../../../assets/rightsign.png" alt />
          </div>
          <p>活动打卡成功</p>
          <div class="time_block">
            <img src="../../../assets/timesign.png" alt />
            <span>{{nowTime}}</span>
          </div>
          <!-- <button @click="window_show = false">我知道了</button> -->
          <van-button
            type="info"
            :loading="loadingBtn"
            loading-type="spinner"
            @click="closeWindow"
          >我知道了</van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Punchclockapi from "@/api/punchclock";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      err: null,
      token: '',
      timeShow: '',
      dataContent: null, //活动详情
      window_show: false, //遮罩层显示
      headShow: false,
      params: {
        id: "",
        memberId: "",
      },
      memberList: [], // 打卡人员名单
      punchInfo: {
        //打卡信息
        activityId: "",
        longitude: "", //经度
        latitude: "", //纬度
        livePicP: "", // 图片
        placeName: "", //地址名称
        memberId: "",
        punchFlag: false, //是否补卡
        status: 0, // 签到状态 签到=0,签退=1
      },
      signInFlag: false, // 是否签到
      signOutFlag: false, // 是否已经签退
      signFinished: false, // 签到签退完成
      nowTime: "", // 打卡成功后获取当前时间
      dakaShow: false, // 根据当前时间判断是否显示打卡区域
      distanceFlag: true, // 当前是否在打卡范围内
      loadingBtn: false,  // 按钮加载
      isInEnoughTime: false,  // 判断当前时间是否超出签到时间
      backShow: false
    };
  },
  created() {
  },
  mounted() {
    if (this.appName == "爱旌德" || this.appName == "今日和县" || this.appName == "智慧新洲") {
      this.backShow = true
    }
    if (!this.isToken()) {
      Toast("请先登录！")
      this.$router.push('/login')
    }
    this.token = JSON.parse(window.localStorage.userinfo).token;
    // 查询活动参数的配置
    this.params.id = this.$route.query.id;
    this.params.memberId = JSON.parse(window.localStorage.userinfo).id;
    // 打卡的参数
    this.punchInfo.activityId = this.$route.query.id;
    this.punchInfo.memberId = JSON.parse(window.localStorage.userinfo).id;
    // 获取详情接口
    this.indexListJavaPage();

  },
  methods: {
    // 接口数据 java
    indexListJavaPage() {
      Punchclockapi.catDetailByIdSec(this.params)
        .then((res) => {
          if (res.success) {
            this.dataContent = res.data;
            if (this.dataContent.activityEndTime) {
              this.timeShow = true
            } else {
              this.timeShow = false
            }
          }
        })
        .catch((err) => {
          //alert(1111)
          //this.err=JSON.stringify(err)
        });
      Punchclockapi.catMemberById(this.$route.query.id)
        .then((res) => {
          if (res.success) {
            this.memberList = res.data;
          }
          if (res.data.length > 6) {
            this.headShow = false;
          }
        })
        .catch((err) => {
          this.err = JSON.stringify(err)
        });
    },
    goBack() {
      window.uni.navigateTo({ url: '/pages/tabbar/civilizedpractice/civilizedpractice' })
      // window.uni.navigateBack({
      //   delta: 1
      // });
    },
    // 跳到打卡详情页
    topunchcontent() {
      let link =
        window.location.href.split("#/")[0] +
        "#/punchtheclockcontent?id=" +
        this.$route.query.id; //链接
      // window.location.href=link;
      this.link({ url: link, title: "考勤打卡", isToLocation: true });
    },
    // 返回上一层
    neirong() {
      this.$router.go(-1);
    },
    //活动签到签退
    onSignIn() {
      var beginTime = this.dataContent.activityBeginTime.replace(/-/g, "/");
      var date = new Date(beginTime);
      var sign_time = Date.parse(date)
      var now_time = Date.parse(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate());
      if (now_time < sign_time) {
        Toast('活动还未开始，请开始后再打卡！')
      } else {
        Punchclockapi.signActivitySec(this.punchInfo)
          .then((res) => {
            if (res.success == true) {
              this.indexListJavaPage(); // 打卡成功刷新页面
              this.nowTime = this.getNowTime();
              this.window_show = true;
            } else {
              Toast(res.message);
            }
          })
          .catch((err) => {
          });
      }
    },
    // 经纬度距离计算
    getFlatternDistance(lat1, lng1, lat2, lng2) {
      let EARTH_RADIUS = 6378137.0; //单位M
      let radLat1 = this.getRad(lat1);
      let radLat2 = this.getRad(lat2);
      let a = radLat1 - radLat2;
      let b = this.getRad(lng1) - this.getRad(lng2);
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
            Math.cos(radLat1) *
            Math.cos(radLat2) *
            Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000.0;
      return s;
    },
    getRad(d) {
      let PI = Math.PI;
      return (d * PI) / 180.0;
    },
    // 获取当前时间,小时加分钟
    getNowTime() {
      var myDate = new Date();
      var hour = myDate.getHours();
      var minu = myDate.getMinutes();
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minu < 10) {
        minu = "0" + minu;
      }
      return hour + ":" + minu;
    },
    onNodaka() {
      Toast("不在打卡范围内");
    },
    // 关闭弹窗
    closeWindow() {
      this.loadingBtn = true;
      setTimeout(() => {
        this.window_show = false;
        this.loadingBtn = false;
      }, 1500);
    }
  },
};
</script>

<style lang="less" scoped>
.activeDetail {
  position: relative;
  background: #fff;
}
.headDetail {
  width: 100%;
  position: relative;
  .returnContent {
    position: absolute;
    left: 10px;
    top: 36px;
    color: #333;
  }
  img {
    width: 100%;
    height: auto;
  }
  .iconfont {
    font-size: 0.32rem;
  }
}
.activeLIstBox {
  font-size: 0.28rem;
  width: 94%;
  height: auto;
  margin: 0.2rem auto;
  padding: 0.2rem;
  box-sizing: border-box;
  position: relative;
  // overflow: hidden;
  // margin-top: 10vw;
  .activeTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    align-items: center;
    p {
      font-size: 0.36rem;
      font-weight: bold;
    }
    button {
      background-image: linear-gradient(to bottom right, #ff0000, #ffaa25);
      outline: none;
      border: none;
      padding: 0.1rem 0.16rem;
      border-radius: 100px;
      color: #fff;
      // font-size: 14px;
    }
  }
  .activeInformation {
    .info_title {
      display: flex;
      align-items: center;
      font-size: 0.4rem;
      font-weight: bold;
      margin-bottom: 20px;
      .info_intro {
        display: inline-block;
        width: 70vw;
      }
      .info_state {
        font-size: 0.3rem;
        background: #efc7c8;
        color: #dd454a;
        padding: 2px 5px;
        border-radius: 6px;
        margin-left: 5px;
        font-weight: normal;
        width: 20vw;
        text-align: center;
      }
    }
    .font_style {
      display: flex;
      justify-content: flex-start;
      font-size: 0.32rem;
      span {
        display: inline-block;
        color: #8a8a8a;
        // margin-right: 2vw;
        width: 1.6rem;
      }
    }
    .font_icon {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 10px;
      div {
        display: flex;
        align-items: flex-end;
        background: #f3d9db;
        color: #dd454a;
        padding: 0 2vw;
        border-radius: 30px;
        height: 5vw;
        // margin-left: 5vw;
        img {
          width: 5vw;
          height: 5vw;
        }
      }
      span {
        font-size: 0.32rem;
        &:nth-child(1) {
          width: 1.6rem;
          color: #8a8a8a;
        }
        &:nth-child(2) {
          color: #000;
          display: inline-block;
          width: calc(100% - 3.3rem);
          // margin-left: 2vw;
        }
      }
    }
    .join_person {
      font-size: 0.36rem;
      margin: 20px 0 10px;
    }
    .person_head {
      position: relative;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      width: 94%;
      .head_wrapper {
        background: #ff5831;
        width: 16vw;
        height: 16vw;
        border-radius: 50%;
        text-align: center;
        line-height: 16vw;
        color: #fff;
        font-weight: bold;
        font-size: 0.32rem;
        margin: 0 1.5vw 1.5vw 0;
      }
      .jiantou_wrapper {
        position: absolute;
        right: -4vw;
        width: 11vw;
        height: 11vw;
        border-radius: 50%;
        background: #999999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .rotate_jiantou {
        img {
          transform: rotate(180deg);
        }
      }
      .success_div {
        background: #00a0e9;
        font-size: 0.1rem;
        color: #fff;
        width: 12vw;
        margin-top: -5vw;
        margin-left: 2vw;
        text-align: center;
      }
    }
    p {
      width: 100%;
      margin-top: 10px;
      // height: 0.7rem;
      // line-height: 0.7rem;
      font-size: 0.28rem;
    }
  }
  .statusContent {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 200px;
    height: 200px;
    color: #fff;
    background: rgba(240, 133, 133, 0.5);
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    transform: rotate(-45deg) translate(0px, 185px);
  }
  .statusContentBack {
    background: rgb(51, 51, 51, 0.1);
  }
}
.briefIntroduction {
  // margin-top: 30px;
  .activeItroduction {
    font-size: 0.4rem;
    font-weight: bold;
    margin-bottom: 20px;
    // padding-left: 0.2rem;
    position: relative;
  }
  .active_info {
    color: #666;
    font-size: 0.33rem;
    line-height: 0.52rem;
  }
}
.briefIntroductionTwo {
  border-top: 1px solid #eee;
}
.daka_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vw;
  .daka_active {
    position: relative;
    width: 26vw;
    height: 26vw;
    background: #00a0e9;
    border-radius: 50%;
    margin-bottom: 3vw;
    box-shadow: 1px 7px 7px 0px rgba(0, 132, 196, 0.29);
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 0.35rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
  }
  .data_info {
    text-align: center;
    span {
      display: inline-block;
      width: 80vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    button {
      border: none;
      font-size: 0.36rem;
      color: #45afeb;
      background: none;
    }
  }
}
.wrapper {
  .success_block {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    background: #fff;
    width: 80vw;
    margin: 65% auto 0;
    border-radius: 10px;
    .success_head {
      border: 1px solid #e4edff;
      background: #fff;
      width: 30vw;
      height: 30vw;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -8vw;
      img {
        width: 80%;
      }
    }
    .time_block {
      border-radius: 10px;
      width: auto;
      background: #e1e1e1;
      padding: 0 3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6b6b6b;
      img {
        width: 6vw;
      }
    }
    p {
      font-size: 9vw;
      color: #00a0e9;
      font-weight: bold;
      margin: 3vw 0;
    }
    button {
      border-radius: 5px;
      border: none;
      width: 50vw;
      height: 10vw;
      line-height: 10vw;
      text-align: center;
      background: #00a0e9;
      color: #fff;
      font-size: 4vw;
      margin: 5vw 0;
    }
  }
}
.punchButton {
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
  background: rgb(221, 40, 40, 0.8);
  width: 1rem;
  height: 1rem;
  color: #fff;
  border-radius: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  // span{
  //     width: 100%;
  //     text-align: center;
  // }
}
.location_info {
  text-align: center;
  font-size: 0.3rem;
}
</style>